import React, { Component, Suspense } from 'react'
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { useLocalStorage } from 'utils/hooks/storage'
import { STORAGE_KEYS } from 'utils/storage-keys'
import { Editor } from 'views/campaign/landingPageBuilder'
import 'react-confirm-alert/src/react-confirm-alert.css'
import LandingPage from 'views/pages/landing/LandingPage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Login = React.lazy(() => import('./views/pages/login/Login'))

function PrivateRoute({ children }: { children: JSX.Element }) {
  const [user] = useLocalStorage({ key: STORAGE_KEYS.EPIC_ADMIN_CURRENT_USER })

  const location = useLocation()
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

class App extends Component {
  render(): JSX.Element {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <DefaultLayout />
                </PrivateRoute>
              }
            />
            <Route path="/404" element={<Page404 />} />
            <Route path="/unhcr" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/campaign/:id/landingpage" element={<Editor />} />
          </Routes>
        </Suspense>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          theme={'colored'}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </HashRouter>
    )
  }
}

export default App
