import { source as b2 } from './data/blog-2'
import { source as b2s } from './data/icons/blog-2'
import { source as b3 } from './data/blog-3'
import { source as b3s } from './data/icons/blog-3'

import { source as z1 } from './data/footer-1'
import { source as z1s } from './data/icons/footer-1'
import { source as z2 } from './data/footer-2'
import { source as z2s } from './data/icons/footer-2'

import { source as h1 } from './data/header-1'
import { source as h1s } from './data/icons/header-1'
import { source as h2 } from './data/header-2'
import { source as h2s } from './data/icons/header-2'

import { source as r1 } from './data/hero-1'
import { source as r1s } from './data/icons/hero-1'
import { source as r2 } from './data/hero-2'
import { source as r2s } from './data/icons/hero-2'
import { source as r3 } from './data/hero-3'

import { source as p1 } from './data/page-1'
import { source as p2 } from './data/page-2'
import { source as p3 } from './data/page-3'
import { source as p4 } from './data/page-4'

const sources = [
  {
    id: 'page-block-4',
    class: '',
    label: 'UNHCR complete',
    content: p4,
    category: 'Landing Pages',
    // order: 1
  },
  {
    id: 'page-block-3',
    class: '',
    label: 'UNHCR page',
    content: p3,
    category: 'Landing Pages',
    // order: 1
  },
  {
    id: 'page-block-1',
    class: '',
    label: 'Sample page one',
    content: p1,
    category: 'Landing Pages',
    // order: 1
  },
  {
    id: 'page-block-2',
    class: '',
    label: 'Sample page two',
    content: p2,
    category: 'Landing Pages',
    // order: 1
  },
  // {
  //   id: 'blog-block-2',
  //   class: '',
  //   label: b2s,
  //   content: b2,
  //   category: 'Blog',
  //   // order: 1
  // },
  // {
  //   id: 'blog-block-3',
  //   class: '',
  //   label: b3s,
  //   content: b3,
  //   category: 'Blog',
  //   // order: 1
  // },

  {
    id: 'footer-block-1',
    class: '',
    label: z1s,
    content: z1,
    category: 'Footer',
    // order: 1
  },
  {
    id: 'footer-block-2',
    class: '',
    label: z2s,
    content: z2,
    category: 'Footer',
    // order: 1
  },

  {
    id: 'header-block-1',
    class: '',
    label: h1s,
    content: h1,
    category: 'Header',
    // order: 1
  },
  {
    id: 'header-block-2',
    class: '',
    label: h2s,
    content: h2,
    category: 'Header',
    // order: 1
  },

  {
    id: 'hero-block-1',
    class: '',
    label: r1s,
    content: r1,
    category: 'Hero',
    // order: 1
  },
  {
    id: 'hero-block-2',
    class: '',
    label: r2s,
    content: r2,
    category: 'Hero',
    // order: 1
  },
  {
    id: 'hero-block-3',
    class: '',
    label: r2s,
    content: r3,
    category: 'Hero',
    // order: 1
  },
]

export default (editor, options = {}) => {
  const bm = editor.Blocks

  sources.forEach((s) => {
    bm.add(s.id, {
      label: s.label,
      attributes: { class: s.class },
      content: s.content,
      category: { label: s.category, open: s.category === 'Blog' },
    })
  })
}
